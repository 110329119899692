import { useEffect, useMemo, useState } from 'react';
import { useIntersection } from '@mantine/hooks';
import { flatten } from 'lodash';
import NftCard from 'components/NftCard/NftCard';
import NftCardLoader from 'components/NftCard/NftCardLoader';
import { useGetAllNfts } from 'hooks/nfts/useNfts';
import { LoadedPages, NftDetails } from 'types/utils';
import Cover from '../assets/br-hills-cover.jpeg';
import Profile from '../assets/br-hills-profile.png';

function BrHillsCollection() {
  const [loadedPages, setLoadedPages] = useState<LoadedPages | []>([]);

  const { ref: scrollRef, entry } = useIntersection({
    root: document,
    threshold: 1,
  });

  const {
    status,
    data: fetchedPages,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    remove: clearNftsCache,
  } = useGetAllNfts();

  useEffect(
    () => () => {
      clearNftsCache();
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    // Cannot trust

    if (
      typeof hasNextPage !== 'undefined' &&
      hasNextPage &&
      entry?.isIntersecting &&
      status !== 'loading' &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPage, fetchNextPage, entry]);

  useEffect(() => {
    if (
      status === 'success' &&
      fetchedPages.pages &&
      fetchedPages.pages?.length > 0 &&
      fetchedPages.pages[fetchedPages.pages.length - 1].results.length > 0
    ) {
      setLoadedPages((prev) => [...prev, fetchedPages.pages[fetchedPages.pages.length - 1]]);
    }
  }, [status, fetchedPages]);

  const getData = () => {
    if (loadedPages.length === 0 || (loadedPages.length === 1 && loadedPages[0].results.length === 0)) {
      return [];
    }

    const finalData = flatten(loadedPages.map((page) => page?.results));

    return (finalData as NftDetails[]) || [];
  };

  const data = useMemo(
    () => getData(),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadedPages],
  );

  return (
    <div className="relative min-h-screen overflow-x-hidden pt-[4.5rem]">
      <main className="h-full w-full">
        <div className="relative z-0 h-80 w-screen">
          <img src={Cover} className="h-full w-full object-cover" alt="project cover" />
        </div>

        <div className="flex gap-10">
          <section className="relative min-w-[16rem] w-[20%] flex flex-col items-start px-5 pt-5 pb-36 lg:px-10 lg:pt-10">
            <div className="z-[1] flex w-full flex-col pl-5 lg:-mt-28">
              <div className="relative w-fit rounded-3xl p-2 bg-gray-700">
                <img src={Profile} alt="project profile" className="h-36 w-36 rounded-3xl object-cover" />
              </div>

              <header className="mt-5 flex flex-col">
                <h1 className=" text-4xl font-semibold text-white">B R hills</h1>
                <p className="mt-3 text-lg text-gray-200">Collection of all B R Hills NFTs</p>
              </header>
            </div>
          </section>

          <section className="mt-5 w-[80%] flex flex-wrap justify-center md:justify-start">
            {status !== 'loading' && !isFetchingNextPage && data?.length === 0 ? (
              <div className="flex h-full min-h-[50vh] w-full items-center justify-center text-2xl text-gray-800 dark:text-gray-200 xl:text-4xl">
                Sorry no results found. :)
              </div>
            ) : (
              data.map((val) => (
                <NftCard
                  id={val.id}
                  image={val.asset}
                  name={val.name}
                  key={val.id}
                  networkId={val.networkId}
                  owner={val.owner}
                />
              ))
            )}

            {(status === 'loading' || isFetchingNextPage) &&
              Array.from(Array(12).keys()).map((_, ind) => (
                <NftCardLoader
                  key={
                    // eslint-disable-next-line react/no-array-index-key
                    ind
                  }
                />
              ))}

            <div ref={scrollRef} />
          </section>
        </div>
      </main>
    </div>
  );
}

export default BrHillsCollection;
