import { useEffect, useState } from 'react';

import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';

import useArcanaAuth from 'hooks/auth/useArcanaAuth';
import useUserLogin from 'hooks/auth/useUserLogin';
import { setUserData } from 'slices/userSlice';

const useAuth = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSocialLoginTriggered, setIsSocialLoginTriggered] = useState(false);
  const [isEmailLoginTriggered, setIsEmailLoginTriggered] = useState(false);
  const { loggedIn, loginWithSocial, loginWithLink, user, logout } = useArcanaAuth();
  const dispatch = useDispatch();

  const {
    data: loginApiResponse,
    isError: isLoginApiFailed,
    isLoading: isLoginApiLoading,
    isSuccess: isLoginApiSuccess,
    mutate: loginApiMutate,
  } = useUserLogin();

  useEffect(() => {
    if (loggedIn && user && (isSocialLoginTriggered || isEmailLoginTriggered)) {
      setIsEmailLoginTriggered(false);
      setIsSocialLoginTriggered(false);
      loginApiMutate({
        account: user.address,
        firstName: user.name,
        images: {
          profile: user.picture,
        },
        publicKey: user.publicKey,
        email: user.email,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn, user]);

  useEffect(() => {
    if (isLoginApiSuccess) {
      toast.success('Login Successful', {
        id: 'login-success',
      });

      localStorage.setItem('access-token', loginApiResponse.auth_token);
      localStorage.setItem('userData', JSON.stringify(loginApiResponse.userData));
      dispatch(setUserData(loginApiResponse.userData));
      setIsLoading(false);
      window.location.href = '/marketplace';
    }
    if (isLoginApiFailed) {
      toast.error('Login Failed', {
        id: 'login-failed',
      });
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginApiSuccess, isLoginApiFailed]);

  const loginWithSocials = async (provider: 'google') => {
    setIsLoading(true);
    setIsSocialLoginTriggered(true);

    try {
      await loginWithSocial(provider);
    } catch (error) {
      toast.error('Login Failed', {
        id: 'login-failed',
      });
      setIsLoading(false);
      setIsSocialLoginTriggered(false);
    }
  };

  const loginWithEmail = async (email: string) => {
    setIsLoading(true);
    setIsEmailLoginTriggered(true);

    try {
      await loginWithLink(email);
    } catch (error) {
      toast.error('Login Failed', {
        id: 'login-failed',
      });
      setIsLoading(false);
      setIsEmailLoginTriggered(false);
    }
  };

  const logoutUser = async () => {
    await logout();
    localStorage.removeItem('access-token');
    localStorage.removeItem('userData');
    dispatch(setUserData(null));
    window.location.href = '/login';
    toast.success('Logout Successful', {
      id: 'logout-success',
    });
  };

  return {
    isLoading: isLoading || isLoginApiLoading,
    loginWithSocials,
    loginWithEmail,
    logoutUser,
  };
};

export default useAuth;
