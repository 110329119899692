import { useEffect, useState } from 'react';
import { AiOutlineAreaChart } from 'react-icons/ai';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { HiOutlineIdentification } from 'react-icons/hi';
import { IconType } from 'react-icons/lib';
import { MagnifyingGlass } from 'react-loader-spinner';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetAllClaimableMaps } from 'hooks/map/useMaps';
import { generateImageFromCoordinates, getArea, getCenter } from 'utils/utils';

function MapProperty({ Icon, title, value }: { Icon: IconType; title: string; value: string | number }) {
  return (
    <div className="flex flex-col items-center w-fit">
      <Icon className="text-5xl" />
      <p className="font-semibold text-base mt-1">{title}</p>
      <p className="mt-2 text-lg font-semibold w-24 text-center">{value}</p>
    </div>
  );
}

function CollectionClaim() {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [map, setMap] = useState<null | {
    id: number;
    geolocation: {
      type: string;
      coordinates: number[][][];
    };
  }>(null);

  const { data: allMaps, isLoading, isSuccess } = useGetAllClaimableMaps();

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const init = async () => {
      const claimType = params.get('type');

      if (!claimType) {
        navigate('/404');
      }

      switch (claimType) {
        case 'random': {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const randomNFT = (allMaps as any)[Math.floor(Math.random() * (allMaps as any).length)];
          if (!randomNFT?.geolocation) {
            navigate('/404');
            return;
          }
          timeout = setTimeout(() => {
            setMap(randomNFT);
          }, 2000);
          break;
        }

        default:
          navigate('/404');
          break;
      }
    };

    if (isSuccess && allMaps && allMaps?.length !== 0) init();

    return () => {
      clearTimeout(timeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  if (isLoading || !map) {
    return (
      <div className="min-h-screen flex flex-col gap-5 justify-center items-center">
        <MagnifyingGlass visible height="120" width="120" glassColor="darkgray" color="white" />
        <p className="text-2xl font-semibold">Looking for Land</p>
      </div>
    );
  }

  const getFormattedCoordinate = (coordinate: number[]) => `${coordinate[0].toFixed(6)} ${coordinate[1].toFixed(6)}`;

  return (
    <div className="relative">
      <main className="px-5 py-10 pt-[5rem] text-white xl:px-0">
        <section className="flex w-full flex-col gap-10 xl:flex-row xl:gap-0">
          {/* Preview image */}
          <div className="relative h-60 w-full lg:h-[60vh] lg:w-1/2">
            <img
              alt="nft"
              src={generateImageFromCoordinates(map?.geolocation)}
              className="h-full w-full object-cover"
            />
          </div>

          <div className="flex w-full py-4 items-center justify-center lg:px-4 xl:ml-14 xl:w-1/2 xl:px-0">
            <div className="h-fit flex flex-col gap-10 justify-center items-center">
              <div className="flex flex-wrap gap-16">
                <MapProperty Icon={HiOutlineIdentification} title="Land Owner" value="---" />
                <MapProperty Icon={AiOutlineAreaChart} title="Area" value={`${getArea(map?.geolocation)} m²`} />
                <MapProperty
                  Icon={FaMapMarkedAlt}
                  title="Location"
                  value={getFormattedCoordinate(getCenter(map?.geolocation))}
                />
              </div>

              <button
                type="button"
                className="bg-white overflow-hidden mx-auto relative w-60 h-12 text-black mt-5 rounded-full text-xl hover:bg-gray-300"
              >
                <p>Mint NFT</p>
              </button>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default CollectionClaim;
