import axios, { AxiosHeaders, HeadersDefaults } from 'axios';
import toast from 'react-hot-toast';

const axiosClient = axios.create();

axiosClient.defaults.baseURL = 'https://api.earth.dripverse.org/';

type Headers = {
  'Content-Type': string;
  Accept: string;
  Authorization: string;
} & {
  [key: string]: AxiosHeaders;
};

axiosClient.defaults.headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
} as Headers & HeadersDefaults;

// Adding Authorization header for all requests

axiosClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access-token');
    const newConfig = config;
    if (token) {
      (newConfig.headers as Headers).Authorization = token;
    }
    return newConfig;
  },
  (error) => Promise.reject(error),
);

axiosClient.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== '/v1/user/login' && err.response && err.response.status === 401) {
      // Access Token was expired

      toast.error('Session time out. Please login again.', {
        id: 'sessionTimeOut',
      });
      localStorage.setItem('sessionTimeout', 'true');
      window.location.href = '/login';
      return Promise.reject(err);
    }

    return Promise.reject(err);
  },
);

export async function getRequest(args: {
  URL: string;
  headers?: {
    [key: string]: string;
  };
  params?: {
    [key: string]: string;
  };
}) {
  const { URL, headers, params } = args;
  const response = await axiosClient.get(`/${URL}`, {
    headers,
    params,
  });
  return response;
}

export async function postRequest(args: {
  URL: string;
  headers?: {
    [key: string]: string;
  };
  params?: {
    [key: string]: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}) {
  const { URL, headers, payload } = args;
  const response = await axiosClient.post(`/${URL}`, payload, {
    headers,
  });
  return response;
}

export async function putRequest(args: {
  URL: string;
  headers?: {
    [key: string]: string;
  };
  params?: {
    [key: string]: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}) {
  const { URL, headers, payload } = args;
  const response = await axiosClient.put(`/${URL}`, payload, {
    headers,
  });
  return response;
}

export async function deleteRequest(args: {
  URL: string;
  headers?: {
    [key: string]: string;
  };
}) {
  const { URL, headers } = args;
  const response = await axiosClient.delete(`/${URL}`, { headers });
  return response;
}

export async function patchRequest(args: {
  URL: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}) {
  const { URL, payload } = args;
  const response = await axiosClient.patch(`/${URL}`, payload);
  return response;
}
