import { useEffect, useState } from 'react';

import { AuthProvider, CHAIN, UserInfo } from '@arcana/auth';
import { ethers } from 'ethers';

// Config
const appAdd = process.env.REACT_APP_ARCANA_APP_ID || '';

const auth = new AuthProvider(appAdd, {
  position: 'left',
  theme: 'light',
  alwaysVisible: true,
  network: 'testnet',
  chainConfig: {
    chainId: CHAIN.POLYGON_MUMBAI_TESTNET,
    rpcUrl: '',
  },
});

function useArcanaAuth() {
  const [initialized, setInitialized] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState<UserInfo | null>(null);
  const [exchangeRates, setExchangeRates] = useState<{ USD: string; INR: string } | null>(null);
  const [balance, setBalance] = useState<{ MATIC: string; USD: string; INR: string } | null>(null);
  const [isBalanceLoading, setIsBalanceLoading] = useState(false);

  const initializeAuth = async () => {
    await auth.init();
    setInitialized(true);
  };

  // Social Login

  const loginWithSocial = async (socialType: 'google') => {
    if (initialized) {
      await auth.loginWithSocial(socialType);
      setLoggedIn(true);
    }
  };

  // Email Link/ Passwordless login
  const loginWithLink = async (email: string) => {
    if (initialized) {
      await auth.loginWithLink(email);
      setLoggedIn(true);
    }
  };

  // Getting user Accounts
  const getAccounts = async (): Promise<string[] | null> => {
    if (initialized) {
      try {
        const accounts = await auth.provider.request({
          method: 'eth_accounts',
        });
        return accounts as string[];
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        return null;
      }
    }
    return null;
  };

  // Get exchange rates from coinbase api
  const getExchangeRates = async () => {
    const response = await fetch('https://api.coinbase.com/v2/exchange-rates?currency=MATIC');
    const data = await response.json();
    setExchangeRates(data.data.rates);
  };

  // Getting account balance
  const getBalance = async () => {
    try {
      const accounts = await getAccounts();

      if (!accounts || accounts.length === 0 || !exchangeRates) {
        return null;
      }

      return await auth.provider
        .request({
          method: 'eth_getBalance',
          params: [accounts[0], 'latest'],
        })
        .then((currentBalance) => {
          const matic = ethers.utils.formatEther(currentBalance as string);
          const usd = (parseFloat(matic) * parseFloat(exchangeRates.USD)).toFixed(2);
          const inr = (parseFloat(matic) * parseFloat(exchangeRates.INR)).toFixed(2);
          setBalance({ MATIC: matic, USD: usd, INR: inr });
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
    return null;
  };

  // Get provider
  const getProvider = () => {
    if (initialized && loggedIn) {
      return auth.provider;
    }
    return null;
  };

  // Logout
  const logout = async () => {
    if (initialized && loggedIn) {
      await auth.logout();
      setLoggedIn(false);
    }
  };

  useEffect(() => {
    const checkLogin = async () => {
      await initializeAuth();
      if (await auth.isLoggedIn()) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    };
    checkLogin();
    getExchangeRates();
  }, []);

  useEffect(() => {
    if (!loggedIn || !initialized) {
      return;
    }
    const getUserInfo = async () => {
      if (initialized && loggedIn) {
        return auth.getUser();
      }
      return null;
    };

    const setUserInfo = async () => {
      const currentUser = await getUserInfo();
      setUser(currentUser);
    };
    setUserInfo();
  }, [loggedIn, initialized]);

  useEffect(() => {
    if (!loggedIn || !initialized || !exchangeRates) {
      return;
    }
    setIsBalanceLoading(true);

    const GetBalance = async () => {
      await getBalance();
      setIsBalanceLoading(false);
    };

    const timeout = setTimeout(() => {
      GetBalance();
    }, 5000);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(timeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn, initialized, exchangeRates]);

  return {
    loggedIn,
    loginWithSocial,
    loginWithLink,
    getAccounts,
    logout,
    user,
    balance,
    getProvider,
    isBalanceLoading,
    auth,
  };
}

export default useArcanaAuth;
