/* eslint-disable import/no-cycle */
import { configureStore } from '@reduxjs/toolkit';

import UserReducer from 'slices/userSlice';
import UtilsReducer from 'slices/utilsSlice';

export function makeStore() {
  return configureStore({
    reducer: {
      user: UserReducer,
      utils: UtilsReducer,
    },
  });
}

const store = makeStore();

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
