import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import Logo from 'assets/earth-logo.png';
import HeaderRight from 'components/Header/HeaderRight';
import { setUserData } from 'slices/userSlice';

function Header() {
  const dispatch = useDispatch();

  useEffect(() => {
    const userDataFromLocalStorage = localStorage.getItem('userData');
    if (userDataFromLocalStorage) {
      dispatch(setUserData(JSON.parse(userDataFromLocalStorage)));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <header className="fixed top-0 left-0 z-50 flex w-screen justify-between bg-black/75 px-8 py-4">
      <a href={window.location.origin}>
        <img src={Logo} alt="drip earth logo" className="h-10 w-10 object-contain" />
      </a>

      <HeaderRight />
    </header>
  );
}

export default Header;
