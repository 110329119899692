import { Link } from 'react-router-dom';
import NftCardOwnerInfo from 'components/NftCard/NftCardOwnerInfo';
import localChainList from 'constants/chainList';
import { useAppSelector } from 'hooks/utils/reduxDefaultHooks';
import { getRemoteChainList } from 'slices/utilsSlice';

type NftCardProps = {
  image: string;
  name: string;
  id: number;
  networkId: number;
  owner: {
    firstName: string;
    lastName: string;
    images: {
      profile: string;
    };
  };
};

function NftCard({ image, name, id, networkId, owner }: NftCardProps) {
  const remoteChainList = useAppSelector(getRemoteChainList);

  const getNetworkLogo = () => {
    const currency = remoteChainList?.find((val) => val.id?.toString() === networkId?.toString())?.supportedCurrency[0]
      .name;

    const localImage = localChainList.find((val) => val.currency?.toLowerCase() === currency?.toLowerCase())?.image;

    return localImage;
  };

  return (
    <Link to={`/nft/${id}`}>
      <div className="relative m-6 h-96 w-64 cursor-pointer overflow-hidden rounded-xl bg-white p-2 text-gray-800 transition-all duration-500 hover:-translate-y-5 2xl:h-[27rem] 2xl:w-[21rem]">
        <div className="relative h-4/6 w-full">
          <img alt="nft" src={image} className="h-full w-full rounded-xl object-cover" />
        </div>

        <div className="flex h-2/6 flex-col justify-between pb-4">
          <p className="m-2 text-xl font-semibold 2xl:text-2xl">{name}</p>
          <NftCardOwnerInfo owner={owner} />
        </div>
        <div className="absolute top-2 right-2 h-10 w-10 2xl:h-12 2xl:w-12">
          <img alt="network logo" src={getNetworkLogo()} className="h-full w-full object-contain" />
        </div>
      </div>
    </Link>
  );
}

export default NftCard;
