import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';

import { getRequest, postRequest } from 'utils/axiosInstance';
import getUrl from 'utils/getUrls';

// --------------------- Get all Maps -----------------

const queryClient = new QueryClient();

const getAllMaps = async () => {
  const response = await getRequest({
    URL: getUrl('map'),
  }).then((res) => res.data.data?.map);

  return response as {
    nftId?: number;
    geolocation: {
      type: string;
      coordinates: number[][][];
    };
  }[];
};

export const useGetAllMaps = () =>
  useQuery(['allMaps'], () => getAllMaps(), {
    refetchOnWindowFocus: false,
  });

// --------------------- Get all Claimable Maps -----------------

const getAllClaimableMaps = async () => {
  const response = await getRequest({
    URL: getUrl('claimableMap'),
  }).then((res) => res.data.data?.map);

  return response as {
    id: number;
    nftId?: number;
    geolocation: {
      type: string;
      coordinates: number[][][];
    };
  }[];
};

export const useGetAllClaimableMaps = () =>
  useQuery(['allClaimableMaps'], () => getAllClaimableMaps(), {
    refetchOnWindowFocus: false,
  });

// --------------------- Get map by id -----------------

const getMapById = async (id: number) => {
  const response = await getRequest({
    URL: `${getUrl('map')}/${id}`,
  }).then((res) => res.data?.data);

  return response;
};

export const useGetMapById = (id: number) =>
  useQuery(['mapById', id], () => getMapById(id), {
    refetchOnWindowFocus: false,
  });

// --------------------- Post new map -----------------

type NewMapPayload = {
  geolocation: {
    type: string;
    coordinates: number[][][];
  };
  nftId?: number;
};

const postMap = async (data: NewMapPayload) => {
  const response = await postRequest({
    URL: getUrl('map'),
    payload: data,
  }).then((res) => res.data);

  return response;
};

export const useCreateNewMap = () =>
  useMutation((data: NewMapPayload) => Promise.resolve(postMap(data)), {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['allMaps'],
      });

      queryClient.refetchQueries({
        queryKey: ['allMaps'],
      });
    },
  });
