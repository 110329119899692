import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { postRequest } from 'utils/axiosInstance';
import getUrl from 'utils/getUrls';

type UserLoginData = {
  account: string;
  firstName: string | undefined;
  images: {
    profile: string | undefined;
  };
  publicKey: string;
  email: string | undefined;
};

const postUserData = async (data: UserLoginData) => {
  const response = await postRequest({
    URL: getUrl('userLogin'),
    payload: data,
  }).then((res: AxiosResponse) => ({
    auth_token: res.data?.data['X-Auth-Token'],
    userData: res.data?.data.user,
  }));

  return response;
};

const useUserLogin = () => useMutation((data: UserLoginData) => postUserData(data), {});

export default useUserLogin;
