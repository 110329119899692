/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'store';
import type { RemoteChainList, NetworkInfo, PlottedGeoJson } from 'types/utils';

type UtilsState = {
  remoteChainList: RemoteChainList[];
  networkOptions: NetworkInfo[];
  allGeoJsons: PlottedGeoJson[];
};

const initialState: UtilsState = {
  remoteChainList: [],
  networkOptions: [],
  allGeoJsons: [],
};

export const utilsSlice = createSlice({
  name: 'utils',
  initialState,
  reducers: {
    setRemoteChainList: (state, action: PayloadAction<RemoteChainList[]>) => {
      state.remoteChainList = action.payload;
    },
    setNetworkOptions: (state, action: PayloadAction<NetworkInfo[]>) => {
      state.networkOptions = action.payload;
    },
    setAllGeoJsons: (state, action: PayloadAction<PlottedGeoJson[]>) => {
      state.allGeoJsons = action.payload;
    },
  },
});

export const { setRemoteChainList, setNetworkOptions, setAllGeoJsons } = utilsSlice.actions;

export const getRemoteChainList = (state: AppState) => state.utils.remoteChainList;

export const getNetworkOptions = (state: AppState) => state.utils.networkOptions;

export const getAllGeoJsons = (state: AppState) => state.utils.allGeoJsons;

export default utilsSlice.reducer;
