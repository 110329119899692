import type { IconType } from 'react-icons';

type AnimatedLinkProps = {
  to: string;
  text: string;
  Icon: IconType;
};

function AnimatedLink({ to, text, Icon }: AnimatedLinkProps) {
  return (
    <a href={to} target="_blank" rel="noopener noreferrer">
      <div className="text-gray-200 transition duration-300 group hover:text-white">
        <div className={`flex items-center justify-center `}>
          <Icon className="mr-1" />
          <div className="mr-6 cursor-pointer">{text}</div>
        </div>

        <span
          className={`block h-0.5 w-[87%] max-w-0 transition-all duration-500
            group-hover:max-w-full `}
        />
      </div>
    </a>
  );
}

export default AnimatedLink;
