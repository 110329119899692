import { useEffect, useState } from 'react';

import { Menu } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import { CgProfile } from 'react-icons/cg';
import { FiLogOut } from 'react-icons/fi';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';

import { useSelector } from 'react-redux';

import MenuOption from 'components/Header/ProfileMenu/MenuOption';
import UserProfile from 'components/Header/UserProfile';
import useAuth from 'hooks/auth/useAuth';
import { getUserData } from 'slices/userSlice';

function ProfileMenu() {
  const [opened, setOpened] = useState(false);
  const ref = useClickOutside(() => setOpened(false));
  const { logoutUser } = useAuth();
  const userData = useSelector(getUserData);

  useEffect(() => {
    setOpened(false);

    // eslint-disable-next-line
  }, [window.location.href]);

  const classNames = {
    dropdown: 'bg-white text-gray-800 border-0 h-fit rounded-2xl mt-1',
  };

  return (
    <Menu classNames={classNames} shadow="md" opened={opened}>
      <div ref={ref}>
        <Menu.Target>
          <div
            className={`flex ${
              opened && 'bg-gray-700'
            } cursor-pointer items-center gap-1 rounded-full px-4 py-1 transition-all duration-300 hover:bg-gray-700`}
            onClick={() => setOpened((o) => !o)}
            onKeyDown={() => setOpened((o) => !o)}
            role="button"
            tabIndex={0}
          >
            <UserProfile />
            {opened ? (
              <MdExpandLess className="text-3xl text-gray-200" />
            ) : (
              <MdExpandMore className="text-3xl text-gray-200" />
            )}
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          <div className="w-full">
            <a href={`/user/${userData?.id}`}>
              <MenuOption value="Profile" Icon={CgProfile} />
            </a>

            <MenuOption value="Logout" Icon={FiLogOut} onClick={logoutUser} />
          </div>
        </Menu.Dropdown>
      </div>
    </Menu>
  );
}

export default ProfileMenu;
