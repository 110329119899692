import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { getRequest } from 'utils/axiosInstance';
import getUrl from 'utils/getUrls';
import { ethAddressWithout0x } from 'utils/utils';

// --------------------- Get all nfts -----------------
const getAllNfts = async (page: string) => {
  // const response = await drip.getNft({ userAccount, page }, "page");
  // const res = response[0];

  const response = await getRequest({
    URL: getUrl('allNfts'),
    params: { page },
  }).then((res) => res.data.data);

  return {
    results: response.nfts,
    nextPage: Number(page + 1),
    totalPages: Number(response.totalPages),
    canQueryNextPage: response.canQueryNextPage as boolean,
  };
};

export const useGetAllNfts = () =>
  useInfiniteQuery(['allNfts'], ({ pageParam = 1 }) => getAllNfts(pageParam), {
    getNextPageParam: ({ nextPage, canQueryNextPage }) => {
      if (canQueryNextPage) return nextPage;
      return undefined;
    },
    refetchOnWindowFocus: false,
  });

// --------------------- Get all nfts of a user -----------------

const getNftsOfUser = async (page: string, userAccount: string) => {
  // const response = await drip.getNft({ userAccount, page }, "page");
  // const res = response[0];

  const formattedUserAccount = ethAddressWithout0x(userAccount?.toLowerCase());

  const response = await getRequest({
    URL: getUrl('userNfts'),
    params: { page, userAccount: formattedUserAccount },
  }).then((res) => res.data.data[0]);

  return {
    results: response.nfts,
    nextPage: Number(page + 1),
    totalPages: Number(response.totalPages),
    canQueryNextPage: response.canQueryNextPage,
  };
};

export const useGetNftsOfUser = (userAccount: string) =>
  useInfiniteQuery(['userNfts', userAccount], ({ pageParam = 1 }) => getNftsOfUser(pageParam, userAccount), {
    getNextPageParam: ({ nextPage, canQueryNextPage }) => {
      if (canQueryNextPage) return nextPage;
      return undefined;
    },
    refetchOnWindowFocus: false,
  });

// --------------------- Get nft data by id -----------------

const getNftById = async (id: string) => {
  const response = await getRequest({
    URL: `${getUrl('allNfts')}/${id}`,
  }).then((res) => res.data.data[0]);

  return response;
};

export const useGetNftById = (id: string) =>
  useQuery(['nftById', id], () => getNftById(id), {
    refetchOnWindowFocus: false,
  });
