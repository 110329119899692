interface UrlList {
  userLogin: string;
  allNfts: string;
  userNfts: string;
  userProfile: string;
  presignedUrl: string;
  map: string;
  claimableMap: string;
}

const urlList: UrlList = {
  userLogin: 'v1/user/login',
  allNfts: 'v1/nft',
  userNfts: 'v1/nft/user',
  userProfile: 'v1/user',
  presignedUrl: 's3/presignedurl',
  map: 'v1/map',
  claimableMap: 'v1/map/claimable',
};

/**
 *
 * @param keyVal key value of the url
 * @returns url string without base url
 * @example
 * getUrl('userLogin') // 'v1/user/login'
 */
const getUrl = (keyVal: keyof UrlList) => urlList[keyVal];

export default getUrl;
