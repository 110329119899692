import { IconType } from 'react-icons';

type MenuOptionProps = {
  Icon: IconType;
  value: string;
  onClick?: () => void;
};

function MenuOption({ Icon, value, onClick }: MenuOptionProps) {
  return (
    <div
      className="my-2 flex w-32 items-center gap-2 rounded-sm px-5 py-2 hover:bg-gray-300"
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={0}
      role="button"
    >
      <Icon />
      <p>{value}</p>
    </div>
  );
}

export default MenuOption;
