import Avatar from 'assets/avatar.jpg';

type NftCardOwnerInfoProps = {
  owner: {
    firstName: string;
    lastName: string;
    images: {
      profile: string;
    };
  };
};

function NftCardOwnerInfo({ owner }: NftCardOwnerInfoProps) {
  const getOwnerInfo = () => {
    if (owner?.firstName) {
      return `${owner?.firstName} ${owner?.lastName || ''}`;
    }
    return 'Anonymous';
  };

  const shouldShowProfileImage = getOwnerInfo() !== 'Anonymous';

  return (
    <div className="flex items-center justify-between rounded-xl bg-gray-300 h-12 px-4 transition-all duration-300 hover:bg-gray-400">
      <div className="flex items-center">
        {shouldShowProfileImage && (
          <img
            alt="nft owner"
            src={owner?.images.profile || Avatar}
            className="mr-2 h-8 w-8 rounded-full object-cover"
          />
        )}
        <p className={getOwnerInfo() === 'Anonymous' ? 'font-semibold' : ''}>{getOwnerInfo()}</p>
      </div>
    </div>
  );
}

export default NftCardOwnerInfo;
