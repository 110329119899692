import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

// --------------------- Get all networks -----------------

const getAllNetworks = async () => {
  const response = await axios
    .get('https://api.alpha.dripverse.org/v1/network')
    .then((res) => res.data?.data?.networkData);

  return response;
};

const useGetAllNetworks = (enabled: boolean) =>
  useQuery(['allNetworks'], () => getAllNetworks(), {
    refetchOnWindowFocus: false,
    enabled,
  });

export default useGetAllNetworks;
