import { useEffect } from 'react';

import localChainList from 'constants/chainList';
import { useAppDispatch, useAppSelector } from 'hooks/utils/reduxDefaultHooks';
import { getRemoteChainList, setNetworkOptions } from 'slices/utilsSlice';

const useSetNetworkOptions = () => {
  const remoteChainList = useAppSelector(getRemoteChainList);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const optionsArray = remoteChainList
      ?.filter((val) => val.state !== 'deprecated')
      ?.map((val) => {
        const img = localChainList?.find((item) => item.currency === val.supportedCurrency[0].name)?.image;

        return {
          label: val.name,
          value: val.name,
          image: img || '',
          disabled: val.state === 'alpha',
          state: val.state,
          networkId: val.id,
          supportedCurrency: val.supportedCurrency[0].name,
        };
      });

    dispatch(setNetworkOptions(optionsArray));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteChainList]);
};

export default useSetNetworkOptions;
