const localChainList = [
  {
    image: 'https://assets.dripverse.org/logos/ethereum.svg',
    currency: 'eth',
    name: 'ethereum',
    networkId: '1',
    scanLink: 'https://etherscan.io/',
    testScanLink: 'https://rinkeby.etherscan.io/',
  },
  {
    image: 'https://assets.dripverse.org/logos/polygon.svg',
    currency: 'matic',
    name: 'polygon',
    networkId: '137',
    scanLink: 'https://polygonscan.com/',
    testScanLink: 'https://mumbai.polygonscan.com/',
  },
];

export default localChainList;
