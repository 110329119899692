import { IoHome } from 'react-icons/io5';
import { useSelector } from 'react-redux';

import ProfileMenu from 'components/Header/ProfileMenu/ProfileMenu';
import { getUserData } from 'slices/userSlice';

function HeaderRight() {
  const userData = useSelector(getUserData);
  const isHomePage = window.location.pathname === '/';

  const getRightSection = () => {
    if (userData) {
      return <ProfileMenu />;
    }
    if (isHomePage) {
      return (
        <a href="/login">
          <button type="button" className="rounded-md bg-white py-1 px-4 text-sm text-black hover:opacity-70">
            Login
          </button>
        </a>
      );
    }
    return (
      <a href="/">
        <IoHome className="cursor-pointer text-xl text-white hover:opacity-70" />
      </a>
    );
  };

  return (
    <div className="flex items-center gap-3">
      {userData && (
        <a href="/map">
          <button
            type="button"
            className="rounded-md border-2 border-white bg-white py-1 px-4 transition-all duration-300 hover:scale-105"
          >
            <p className="text-sm text-black">Map</p>
          </button>
        </a>
      )}

      <a href="/marketplace">
        <button
          type="button"
          className="rounded-md border-2 border-white py-1 px-4 transition-all duration-300 hover:scale-105"
        >
          <p className="text-sm">Marketplace</p>
        </button>
      </a>

      {getRightSection()}
    </div>
  );
}

export default HeaderRight;
