import { useSelector } from 'react-redux';
import Avatar from 'assets/avatar.jpg';
import { getUserData } from 'slices/userSlice';

function UserProfile() {
  const data = useSelector(getUserData);
  return (
    <div className="z-10 flex items-center gap-3">
      <img
        src={data?.images?.profile || Avatar}
        className="h-8 w-8 cursor-pointer rounded-full object-contain"
        alt="user profile"
      />
    </div>
  );
}

export default UserProfile;
