/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { User } from 'types/utils';

type UserState = {
  userData: User | null;
};

const initialState: UserState = {
  userData: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action: { payload: User | null }) => {
      state.userData = action.payload;
    },
  },
});

export const { setUserData } = userSlice.actions;

export const getUserData = (state: AppState) => state.user.userData;

export default userSlice.reducer;
