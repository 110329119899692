import area from '@turf/area';
import booleanContains from '@turf/boolean-contains';
import booleanOverlap from '@turf/boolean-overlap';
import findCenter from '@turf/center';
import { polygon, points } from '@turf/helpers';
import { DripSDK } from 'dripverse';
import { toast } from 'react-hot-toast';
import { Geometry, PlottedGeoJson } from 'types/utils';
import generateId from './helpers';

/**
 * @remarks DripSDK instance initialized with project key and 'alpha' as environment
 * @returns DripSDK instance
 */
export const drip = new DripSDK(process.env.REACT_APP_DRIP_PROJECT_KEY as string, 'alpha');

/**
 *
 * @param str string to be truncated
 * @param num number of characters to be truncated to
 * @returns truncated string
 * @example
 * truncateString('hello world', 5) // 'hello...'
 * truncateString('hello world', 100) // 'hello world'
 * truncateString('hello world', 0) // 'hello world'
 */
export const truncateString = (str: string, num = 100) => {
  if (str.length > num) {
    return `${str.slice(0, num)}...`;
  }
  return str;
};

/**
 * @param input ethereum address to be truncated
 * @param num number of characters to be truncated to
 * @returns truncated ethereum address
 * @example
 * truncateAddress('0x1234567890123456789012345678901234567890', 6) // '0x123456...901234'
 * truncateAddress('0x1234567890123456789012345678901234567890', 0) // '0x1234567890123456789012345678901234567890'
 * truncateAddress('0x1234567890123456789012345678901234567890', 100) // '0x1234567890123456789012345678901234567890'
 */
export const truncateAddress = (input: string, num = 6) => {
  if (input) {
    return `${input.substring(0, num)}...${input?.substring(input.length - (num - 1))}`;
  }
  return input;
};

/**
 *
 * @param val value to be checked
 * @remarks checks if value is truthy or not (0 is considered truthy)
 * @returns boolean
 * @example
 * Falsy(0) // true
 * Falsy('') // false
 * Falsy(null) // false
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Truthy = (val: any) => {
  if (val === 0 || val) return true;
  return false;
};

/**
 * @param value value to be checked
 * @remarks checks if value is truthy or not (empty string is considered truthy)
 * @returns boolean
 * @example
 * Falsy('') // true
 * Falsy(1) // true
 * Falsy(null) // false
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isTruthyAllowEmptyString = (value: any): boolean => {
  // type should be string and also allow empty string
  if (typeof value === 'string') {
    return true;
  }
  return false;
};

/**
 * @param str string to be checked
 * @remarks strips all emojis from a string
 * @returns trimmed string
 * @example
 * stripEmojis('👨‍👩‍👧‍👦') // ''
 * stripEmojis('hello 👨‍👩‍👧‍👦👨‍👩‍👧‍👦👨‍👩‍👧‍👦') // 'hello'
 * stripEmojis('hello') // 'hello'
 */
export const stripEmojis = (str: string) =>
  str
    ?.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      '',
    )
    .replace(/\s+/g, ' ')
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replaceAll(' ', '')
    .trim();

/**
 * @param userId current user id
 * @param name name of the image with extension
 * @returns full public s3 image url of the image
 * @example
 * getS3ImageURL(1, 'image.png') // 'https://dripearth.s3.amazonaws.com/platform/users/1-image.png'
 * getS3ImageURL(1, 'image.jpg') // 'https://dripearth.s3.amazonaws.com/platform/users/1-image.jpg'
 */
export const getS3ImageURL = (userId: number, name: string) => {
  const bucket = 'dripearth';

  return `https://${bucket}.s3.amazonaws.com/platform/users/${userId}-${name}`;
};

/**
 * @param args allGeoJsons: all the previous geojsons, newGeometry: new geojson to be checked
 * @remarks checks if the new geojson overlaps with any of the previous geojsons
 * @returns boolean
 * @example
 * checkOverlap({allGeoJsons: [{geometry: {coordinates: [[[0, 0], [0, 1], [1, 1], [1, 0], [0, 0]]]}}], newGeometry: {coordinates: [[[0, 0], [0, 1], [1, 1], [1, 0], [0, 0]]]}}) // true
 * checkOverlap({allGeoJsons: [{geometry: {coordinates: [[[0, 0], [0, 1], [1, 1], [1, 0], [0, 0]]]}}], newGeometry: {coordinates: [[[1, 1], [1, 2], [2, 2], [2, 1], [1, 1]]]}}) // false
 */
export const checkOverlap = (args: {
  allGeoJsons: { geometry: { coordinates: number[][][] } }[];
  newGeometry: Geometry;
}) => {
  const { allGeoJsons, newGeometry } = args;
  const isOverlapping = allGeoJsons.some((oldGeoJson) => {
    const oldPolygon = polygon(oldGeoJson.geometry.coordinates);
    const newPolygon = polygon(newGeometry.coordinates);
    return (
      booleanOverlap(oldPolygon, newPolygon) ||
      booleanContains(oldPolygon, newPolygon) ||
      booleanContains(newPolygon, oldPolygon)
    );
  });

  return isOverlapping;
};

/**
 * @param geometry geojson geometry object
 * @remarks calculates the area of the geojson in sq meters
 * @returns area in sq meters
 * @example
 * getArea({coordinates: [[[0, 0], [0, 1], [1, 1], [1, 0], [0, 0]]]}) // 111194.93
 */
export const getArea = (geometry: { coordinates: number[][][] }) => {
  const derivedPolygon = polygon(geometry.coordinates);
  const areaInSqMeters = area(derivedPolygon);
  return areaInSqMeters.toFixed(2);
};

/**
 * @param address ethereum address with 0x
 * @remarks removes 0x from the address if present and returns the address without 0x prefix, else returns the address as is
 * @returns address without 0x
 * @example
 * ethAddressWithout0x('0x1234567890123456789012345678901234567890') // '1234567890123456789012345678901234567890'
 * ethAddressWithout0x('1234567890123456789012345678901234567890') // '1234567890123456789012345678901234567890'
 */
export const ethAddressWithout0x = (address: string) => {
  if (address.slice(0, 2) !== '0x') return address;
  const addressWithout0x = address.slice(2);
  return addressWithout0x;
};

export const generateImageFromCoordinates = (geometry: Geometry, zoom?: number) => {
  const pathString = geometry.coordinates[0].map((coordinate) => `${coordinate[1]},${coordinate[0]}`).join('|');
  const imageUrl = `https://maps.googleapis.com/maps/api/staticmap?size=640x640&${
    zoom ? `zoom=${zoom}` : ''
  }&maptype=satellite&path=color:0x88fa73|fillcolor:0x39FF14|${pathString}&key=${
    process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  }`;
  return imageUrl;
};

export const generateImage = (args: {
  geometry: Geometry;
  allGeoJsons: PlottedGeoJson[];
  setGeometry?: (geometry: Geometry) => void;
  setImage: (image: File) => void;
  setIsMintModalOpen: (isOpen: boolean) => void;
  zoom?: number;
}) => {
  const { geometry, allGeoJsons, setGeometry, setImage, setIsMintModalOpen } = args;

  if (!geometry) return;

  if (
    checkOverlap({
      allGeoJsons,
      newGeometry: geometry,
    })
  ) {
    toast.error(
      "Part of complete area you've selected has previously been claimed. Please re-check and try again. If the problem persists, kindly get in touch with us.",
      {
        id: 'area-overlap',
      },
    );
    return;
  }

  if (setGeometry) setGeometry(geometry);
  // const encodedObject = encodeURIComponent(JSON.stringify(geoJson));
  const imageUrl = generateImageFromCoordinates(geometry, args.zoom);
  const id = generateId();
  const fileName = `${id}.jpg`;

  fetch(imageUrl).then(async (response) => {
    // const contentType = response.headers.get('content-type');
    const blob = await response.blob();
    const file = new File([blob], fileName);
    setImage(file);
    setIsMintModalOpen(true);
  });
};

export const getCenter = (geometry: Geometry) => {
  const features = points(geometry.coordinates[0]);
  const center = findCenter(features);
  return center.geometry.coordinates;
};
