import { useHover } from '@mantine/hooks';
import { BsTwitter, BsInstagram } from 'react-icons/bs';
import { FaAngellist } from 'react-icons/fa';
import { GiArchiveResearch, GiPlatform } from 'react-icons/gi';
import { IoLibraryOutline } from 'react-icons/io5';
import { MdOutlineEmail } from 'react-icons/md';
import { RiArticleLine } from 'react-icons/ri';

import DripverseLogo from 'assets/earth-logo.png';
import AnimatedLink from 'components/Footer/AnimatedLink';

function Footer() {
  const { hovered: twitterHovered, ref: twitterHoverRef } = useHover();
  const { hovered: instaHovered, ref: instaHoverRef } = useHover();

  return (
    <footer className="flex flex-col items-center justify-center w-screen gap-10 h-fit p-14">
      <div className="flex flex-col w-screen gap-10 h-fit lg:flex-row">
        <section className="flex flex-col items-center justify-center h-full lg:w-1/4">
          <img alt="dripverse earth logo" src={DripverseLogo} className="cursor-pointer h-36 w-36" />
          <p className="text-sm leading-[1.2] text-center mt-5">Support Sustainable Development with DripVerse Earth</p>
        </section>

        <section className="flex flex-col items-center justify-center h-full lg:w-1/4 lg:items-start">
          <p className="mb-2 font-semibold">Of the DripVerse</p>

          <div className="w-fit">
            <AnimatedLink to="https://alpha.dripverse.org/" text="DripVerse Alpha Platform" Icon={GiPlatform} />
          </div>
          <div className="w-fit">
            <AnimatedLink to="https://blog.dripverse.org/" text="Blog" Icon={RiArticleLine} />
          </div>
          <div className="w-fit">
            <AnimatedLink to="https://library.dripverse.org" text="Library" Icon={IoLibraryOutline} />
          </div>
        </section>

        <section className="flex flex-col items-center justify-center h-full lg:w-1/4 lg:items-start">
          <p className="mb-2 font-semibold">For the DripVerse</p>
          <div className="w-fit">
            <AnimatedLink
              to="https://library.dripverse.org/papers/litepaper/"
              text="Litepaper"
              Icon={GiArchiveResearch}
            />
          </div>
        </section>

        <section className="flex flex-col items-center justify-center w-full h-full lg:w-1/4 lg:items-start">
          <p className="mb-2 font-semibold">By the DripVerse</p>
          <div className="w-fit">
            <AnimatedLink to="mailto:hello@dripverse.org" text="Contact Us" Icon={MdOutlineEmail} />
          </div>
          <div className="w-fit">
            <AnimatedLink to="https://angel.co/company/acyclic/jobs" text="We are hiring" Icon={FaAngellist} />
          </div>
        </section>
      </div>

      <section className="flex items-center justify-center w-full h-full gap-10 lg:w-1/4">
        <a href="https://twitter.com/0xDripVerse" className="-mb-2" target="_blank" rel="noreferrer">
          <div ref={twitterHoverRef}>
            <BsTwitter
              size={20}
              className={` hover:text-[#1DA1F2] hover:brightness-110 hover:dark:text-[#1DA1F2] ${
                twitterHovered && 'twitterShadow'
              }`}
            />
          </div>
        </a>
        <a href="https://instagram.com/0xDripVerse" className="-mb-2" target="_blank" rel="noreferrer">
          <div ref={instaHoverRef}>
            <BsInstagram
              size={20}
              className={` hover:text-[#E1306C] hover:brightness-110  hover:dark:text-[#E1306C] ${
                instaHovered && 'instaShadow'
              }`}
            />
          </div>
        </a>
      </section>
    </footer>
  );
}

export default Footer;
