import { lazy, Suspense, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ForestBackground from 'assets/forest-background.jpeg';
import Footer from 'components/Footer/Footer';

import Header from 'components/Header/Header';
// import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';

import useArcanaAuth from 'hooks/auth/useArcanaAuth';
import useAuth from 'hooks/auth/useAuth';
import useGetAllNetworks from 'hooks/network/useNetworks';
import { useAppDispatch, useAppSelector } from 'hooks/utils/reduxDefaultHooks';
import useSetNetworkOptions from 'hooks/utils/useSetNetworkOptions';

import BrHillsCollection from 'pages/BrHillsCollection';
import CollectionClaim from 'pages/CollectionClaim';
import { getRemoteChainList, setRemoteChainList } from 'slices/utilsSlice';

const Home = lazy(() => import('pages/Home'));
const Login = lazy(() => import('pages/Login'));
const MapPage = lazy(() => import('pages/Map'));
const MarketplacePage = lazy(() => import('pages/MarketplacePage'));
const NftDetails = lazy(() => import('pages/NftDetails'));
const UserProfile = lazy(() => import('pages/UserProfile'));

function App() {
  useSetNetworkOptions();
  const dispatch = useAppDispatch();
  const remoteChainList = useAppSelector(getRemoteChainList);
  const { auth } = useArcanaAuth();
  const { logoutUser } = useAuth();

  const { data: fetchedNetworks, isSuccess: isNetworksFetchingSuccessful } = useGetAllNetworks(!remoteChainList.length);

  useEffect(() => {
    if (isNetworksFetchingSuccessful && fetchedNetworks) {
      dispatch(setRemoteChainList(fetchedNetworks));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNetworksFetchingSuccessful, fetchedNetworks]);

  useEffect(() => {
    const localUser = localStorage.getItem('userData');

    const logOutCheck = async () => {
      const loggedIn = await auth.isLoggedIn();
      if (localUser && !loggedIn) {
        logoutUser();
      }
    };

    const timer = setTimeout(logOutCheck, 10000);

    return () => clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative overflow-x-hidden">
      <img src={ForestBackground} alt="hero" className="absolute top-0 left-0 z-[-2] h-full w-full object-cover" />
      <div className="dark-overlay absolute top-0 left-0 z-[-1] h-full w-full" />

      <Header />
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/map" element={<MapPage />} />
            <Route path="/marketplace" element={<MarketplacePage />} />
            <Route path="/nft/:id" element={<NftDetails />} />
            <Route path="/user/:id" element={<UserProfile />} />
            <Route path="/collection/1" element={<BrHillsCollection />} />
            <Route path="/collection/1/claim" element={<CollectionClaim />} />
            <Route path="/login" element={<Login />} />

            <Route
              path="*"
              element={
                <div className="flex h-screen items-center justify-center text-center text-4xl font-bold text-white">
                  404 Not Found
                </div>
              }
            />
          </Routes>
        </Suspense>
      </Router>
      <Toaster position="bottom-right" />
      <Footer />
    </div>
  );
}

export default App;
