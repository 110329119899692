import { v1 as uuidv1 } from 'uuid';

/**
 *
 * @returns uuidv1 string
 * @remarks UUID v1 is generated by using a combination the host computers MAC address and the current date and time. In addition to this, it also introduces another random component just to be sure of its uniqueness.
 * @example
 * generateId() // '6c84fb90-12c4-11e1-840d-7b25c5ee775a'
 */
const generateId = () => uuidv1();

export default generateId;
